.main{
  background-image: url(/public/images/bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sub{
  background-image: url(/public/images/bg-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 400px;
}

.service-card {
  height: 100px;
  width: 400px;
}